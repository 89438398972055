<template>
    <div id="Home">
        <section class="bg-banner" style="position: relative;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8 col-md-7">
                        <div class="text-start" style="color: white;">
                            <h1 class="mb-3">Envia <strong>SMS Masivos</strong></h1>
                            <h2 class="mb-5">¡Alertas, Recordatorios, Turnos,
                                Cobranzas, Códigos de verificación y Contenido Multimedia!
                            </h2>
                            <div class="mb-5" >
                                <img src="../assets/media/smsmasivos_multimedia.svg" style="width: 385px;"  alt="...">
                            </div>
                            <div class="mb-5">
                                <router-link to="/consulta" class="pix-btn btn-txt btn-home ">¡Solicitar Demo!</router-link>
                            </div>

                        </div>
                    </div>
                    <div class="col-lg-4 col-md-5 " style="position: relative;">
                        <img src="../assets/media/mobile.gif" style="position: relative;z-index: 2;height: 650px;" class="img-banner" alt="mpckup">
                    </div>

                </div>
            </div>
            <div style="position: absolute;bottom: -2px;">
                <div>
                    <img src="../assets/media/banner-three-shape.png"
                    style="max-width: 100%;height: auto;"
                    alt="...">
                </div>
            </div>
        </section>
        <section class="px-3 py-5">
            <div class="container">
                <div class="row">
                    <h2 class="text-color">¡Prueba ahora nuestra plataforma de envíos!</h2>
                </div>
                <div class="row">
                    <div class="col-sm ">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-card-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                <path
                                    d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                            </svg>
                        </div>
                        <strong>Informes de entrega</strong>
                        <p>Visualiza en tiempo real y online cuando reciben el mensaje tus clientes.</p>
                    </div>
                    <div class="col-sm ">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-card-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                <path
                                    d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                            </svg>
                        </div>
                        <strong>Programa tus mensajes</strong>
                        <p>Programa y envía tus campañas en la fecha y hora que desees de manera muy sencilla.</p>
                    </div>
                    <div class="col-sm ">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-card-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                <path
                                    d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                            </svg>
                        </div>
                        <strong>Remitente personalizable</strong>
                        <p>Personaliza el remitente del mensaje con el nombre de tu negocio o marca.</p>
                    </div>
                    <div class="col-sm ">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="currentColor"
                                class="bi bi-card-image" viewBox="0 0 16 16">
                                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
                                <path
                                    d="M1.5 2A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-13zm13 1a.5.5 0 0 1 .5.5v6l-3.775-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12v.54A.505.505 0 0 1 1 12.5v-9a.5.5 0 0 1 .5-.5h13z" />
                            </svg>
                        </div>
                        <strong>Remitente personalizable</strong>
                        <p>Potente agenda para gestionar tus contactos y grupos, copia & pega o importa tus contactos desde
                            excel, csv, tsv ...</p>
                    </div>
                </div>
            </div>
        </section>
        <section class="px-3 call-to-action">
            <div class="container">
                <h1 class="text-center text-white fw-bold">Comunícate</h1>
                <div class="row justify-content-center mail">
                    <p class="mail text-white">comercial@envio.pe</p>
                </div>
            </div>
        </section>
        <section class="px-3 py-5">
            <div class="container">
                <h2 class="text-color">Clientes que confían en nosotros</h2>
                <div class="container pt-4">
                    <div class="d-sm-inline px-3 py-4">
                        <img src="../assets/media/logo_sky.png" width="170px">
                    </div>
                    <div class="d-sm-inline px-3 py-4">
                        <img src="../assets/media/logo_sky.png" width="170px">
                    </div>
                    <div class="d-sm-inline px-3 py-4">
                        <img src="../assets/media/logo_sky.png" width="170px">
                    </div>
                    <div class="d-sm-inline px-3 py-4">
                        <img src="../assets/media/logo_sky.png" width="170px">
                    </div>
                </div>
            </div>
        </section>
        <section class="px-3 py-5">
            <div class="container">
                <div class="row">
                    <h2 class="text-color">¿Por qué elegir SMS Masivos?</h2>
                </div>
                <div class="row pt-4">
                    <div class="col-sm">
                        <h5>Ventajas</h5>
                        <p class="text-center">
                            Plataforma Web autoadministrable<br>
                            Envío y recepción en tiempo real<br>
                            API - Integración con sistemas<br>
                            Personalización de mensajes<br>
                            Programación de envío día/hora<br>
                            Cobertura nacional e internacional<br>
                            Control horario y de duplicados
                        </p>
                    </div>
                    <div class="col-sm">
                        <h5>¿A quién va dirigido?</h5>
                        <p class="text-center">
                            Consultorios y clínicas médicas<br>
                            Agencias de marketing<br>
                            Agencias de publicidad y eventos<br>
                            Cobranzas y estudios de abogados<br>
                            Pymes y grandes empresas<br>
                            Entidades gubernamentales<br>
                            ONG, sindicatos y clubes
                        </p>
                    </div>
                    <div class="col-sm">
                        <h5>Mensajes de Texto</h5>
                        <p class="text-center">
                            Convocatorias<br>
                            Recordatorios de turnos y reuniones<br>
                            Avisos de vencimiento de deuda<br>
                            Envío de cotizaciones<br>
                            Promociones y notificaciones<br>
                            Comunicaciones internas<br>
                            Invitación y confirmación a eventos
                        </p>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
export default {
    name: "HomeComponent",
};
</script>
<style>
a {
    text-decoration: none;
}

button {
    border: none;
}
li,button{
  font-weight: 600;
}
.bg-banner {
    background-image: linear-gradient(-60deg, #f14100 0%, #201df8 100%);
    height: 1060px;
    padding-top: 290px;

}

@media(max-width: 768px) {
    .bg-banner {
        height: auto;
        padding-top: 120px;
    }
}

.img-banner {
    height: 725px;
}

.pix-btn {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
}
.btn-home{
    color: #7052fb;
    background-color: white;
    border: 2px solid white;
    border-radius: 30px;
    cursor: pointer;
}
.btn-home :hover{
    color: white;
    border: .8px solid #fff;
    background-color: none;

}

.btn-txt {
    font-weight: 600;
    font-size: 20px;

}

.text-color {
    color: #2b2350;
}
.call-to-action{
    background-image: linear-gradient(100deg, #FF5722 0%, #fa9770 100%);
    padding: 111px 0 120px;
    overflow: hidden;
}

.mail{
    font-size: 19px;
    text-align: center;
    line-height: 32px;
    font-weight: 600;
}
</style>
