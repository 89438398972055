<template>
    <section id="consulta" class="container  px-5">
        <div class="row">
            <div class="col-md-8">
                <form class="row shadow g-3 p-3 mt-3 mb-5" style="border-radius: 10px;">
                    <h4 class="text-start">Gracias por tu interes en Envios.pe</h4>
                    <span class="text-start">Completa el siguiente formulario y un agente se comunicará en breve contigo para organizar un demo de nuestro sistema contigo y tus equipos.</span>
                    <div class="col-md-6">
                        <label for="inputNames" class="form-label">Nombres y Apellidos</label>
                        <input type="text" class="form-control" id="inputNames">
                    </div>
                    <div class="col-md-6">
                        <label for="inputMail" class="form-label">Correo profesional</label>
                        <input type="mail" class="form-control" id="inputMail">
                    </div>
                    <div class="col-12">
                        <label for="inputRuc" class="form-label">Ruc</label>
                        <input type="text" class="form-control" id="inputRuc" >
                    </div>
                    <div class="col-md-6">
                        <label  class="form-label">Codigo Pais</label>
                        <input type="text" placeholder="+51" disabled class="form-control" >
                    </div>
                    <div class="col-md-6">
                        <label for="inputPhone" class="form-label">Telefono</label>
                        <input type="number" class="form-control" id="inputPhone">
                    </div>
                    <div class="col-md-6">
                        <label for="inputCompanyName" class="form-label">Nombre de tu empresa</label>
                        <input type="text" class="form-control" id="inputCompanyName">
                    </div>
                    <div class="col-md-6">
                        <label for="inputService" class="form-label">Servicio a Consultar</label>
                        <select id="inputService" class="form-select">
                            <option selected>Service...</option>
                            <option>...</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label for="inputVerified" class="form-label">¿Cuantos usuarios querías verificar / mes?*</label>
                        <select id="inputVerified" class="form-select">
                            <option selected>&lt;100</option>
                            <option>100 - 250</option>
                            <option>250 - 1000</option>
                            <option>1000 - 4000</option>
                            <option>4000+</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label for="inputTimeVerified" class="form-label">¿Cuándo quieres comenzar a verificar
                            usuarios?*</label>
                            <select id="inputTimeVerified" class="form-select">
                            <option selected>&lt;3 meses</option>
                            <option>En 3 - 6 meses</option>
                            <option>Solo estoy investigando</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label for="inputResell" class="form-label">¿Quieres revender los servicios a tus
                            clientes?*</label>
                            <select id="inputResell" class="form-select">
                            <option selected>SI</option>
                            <option>NO</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <button type="submit" class="btn btn-primary container-fluid">Aceptar</button>
                    </div>
                </form>
            </div>

            <div class="col-md-4">
                <div  style="color:#007bff;font-size: 45px;font-weight: 600;font-family: 'Poppins';">
                    Envios.pe
                </div>
            </div>
        </div>

    </section>
</template>
<script>
export default {
    name: "PrecioComponent",

}
</script>
<style>
#consulta{
    margin-top: 100px
}
</style>