<template>
  <div class="container" align="center" style="margin-top: 100px;">
    <h1>Precios SMS Masivos</h1>

    <div class="container">
      <div class="grid">
        <div class="item-1">
          <div class="container">
            <h3>Calcula el precio de tus de SMS</h3>
            <div class="shadow mt-5 p-3" style="border-radius: 10px;">
              <input type="number" v-model="quantityMessage" @input="limpiarValor" :placeholder="placeholderText" class="form-control" >
              <button @click="calcularMultiplicacion" class="btn btn-primary my-3">Calcular</button>
              <div class="mt-3">
                <p class="text-start fw-bold">El monto Total seria: S/{{ precioTotal  }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="item-2  ">
          <!-- <h4>Encuentra las respuestas a tus preguntas frecuentes.</h4>
          <div class="accordion" id="accordionExample">
            <div class="accordion-item rounded-3">
              <h2 class="accordion-header">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                  aria-expanded="true" aria-controls="collapseOne">
                  ¿Que tan rapido se entrega mi mensaje?
                </button>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Tenemos conexiones directas con todos los operadores con un tiempo de entrega garantizado de 5 segundos
                  y
                  una efectividad del 99%.
                </div>
              </div>
            </div>
            <div class="accordion-item mt-4 rounded-3">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                  ¿Que tan rapido se entrega mi mensaje?
                </button>
              </h2>
              <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  La compra mínima es de 300 mensajes. Los mensajes no expiran, así que lo que muchos de nuestros clientes
                  hacen, es anticipar compras para obtener un mejor precio por mensaje.
                </div>
              </div>
            </div>
            <div class="accordion-item mt-4 rounded-3">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                  ¿Dan factura por los mensajes?
                </button>
              </h2>
              <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Claro, puedes solicitar tu factura de manera anticipada para poder emitir el pago, o puedes pagar y tu
                  factura se genera desde el panel de forma automática.
                </div>
              </div>
            </div>
            <div class="accordion-item mt-4 rounded-3">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                  ¿A que compañias puedo enviar mensajes?
                </button>
              </h2>
              <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Tenemos cobertura a todas las compañías de Peru.
                </div>
              </div>
            </div>
            <div class="accordion-item mt-4 rounded-3">
              <h2 class="accordion-header">
                <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                  data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                  ¿Es necesario instalar un software en mi computadora?
                </button>
              </h2>
              <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                  Accede a nuestro panel desde cualquier dispositivo con internet, no necesitas instalar ningún software y
                  la implementación es inmediata.
                </div>
              </div>
            </div>
          </div>-->
          <h3>Precios SMS Masivos</h3>
          <h5>Sin rentas mensuales ni contratos.</h5>
          <table class="table table-striped shadow" style="border-radius: 10px;">
            <tbody>
              <tr>
                <td>
                  <h4>Volumen de SMS</h4>
                </td>
                <td>
                  <h4>Precio</h4>
                </td>
              </tr>
              <tr v-for="precio in Precios" :key="precio.cantidad">
                <td>{{ precio.cantidad }}</td>
                <td>S/ {{ precio.preciosms }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="container my-5">
        <h4>Encuentra las respuestas a tus preguntas frecuentes.</h4>
        <div class="accordion shadow mt-3 p-3" style="border-radius: 10px;" id="accordionExample">
          <div class="accordion-item rounded-3">
            <h2 class="accordion-header">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne"
                aria-expanded="true" aria-controls="collapseOne">
                ¿Que tan rapido se entrega mi mensaje?
              </button>
            </h2>
            <div id="collapseOne" class="accordion-collapse collapse show" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Tenemos conexiones directas con todos los operadores con un tiempo de entrega garantizado de 5 segundos
                y
                una efectividad del 99%.
              </div>
            </div>
          </div>
          <div class="accordion-item mt-4 rounded-3">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                ¿Que tan rapido se entrega mi mensaje?
              </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                La compra mínima es de 300 mensajes. Los mensajes no expiran, así que lo que muchos de nuestros clientes
                hacen, es anticipar compras para obtener un mejor precio por mensaje.
              </div>
            </div>
          </div>
          <div class="accordion-item mt-4 rounded-3">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                ¿Dan factura por los mensajes?
              </button>
            </h2>
            <div id="collapseThree" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Claro, puedes solicitar tu factura de manera anticipada para poder emitir el pago, o puedes pagar y tu
                factura se genera desde el panel de forma automática.
              </div>
            </div>
          </div>
          <div class="accordion-item mt-4 rounded-3">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                ¿A que compañias puedo enviar mensajes?
              </button>
            </h2>
            <div id="collapseFour" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Tenemos cobertura a todas las compañías de Peru.
              </div>
            </div>
          </div>
          <div class="accordion-item mt-4 rounded-3">
            <h2 class="accordion-header">
              <button class="accordion-button collapsed rounded-top" type="button" data-bs-toggle="collapse"
                data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                ¿Es necesario instalar un software en mi computadora?
              </button>
            </h2>
            <div id="collapseFive" class="accordion-collapse collapse" data-bs-parent="#accordionExample">
              <div class="accordion-body">
                Accede a nuestro panel desde cualquier dispositivo con internet, no necesitas instalar ningún software y
                la implementación es inmediata.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
export default {
  name: "PrecioComponent",
  data() {
    return {

      quantityMessage: 0,
      precioTotal:'',

      /* constante precio sms */
      Precios: [
        {
          cantidad: "De 5,001 a 10,000 SMS", preciosms: 0.077
        },
        {
          cantidad: "De 10,001 a 15,000 SMS", preciosms: 0.075
        },
        {
          cantidad: "De 15,001 a 20,000 SMS", preciosms: 0.072
        },
        {
          cantidad: "De 20,001 a 50,000 SMS", preciosms: 0.070
        },
        {
          cantidad: "De 50,001 a 100,000 SMS", preciosms: 0.069
        },
        {
          cantidad: "De 100,001 a 200,000 SMS", preciosms: 0.067
        },
        {
          cantidad: "De 200,001 a 300,000 SMS", preciosms: 0.065
        },
        {
          cantidad: "De 300,001 a 500,000 SMS", preciosms: 0.062
        },
        {
          cantidad: "De 500,001 a 800,000 SMS", preciosms: 0.060
        },
        {
          cantidad: "De 800,001 a 900,000 SMS", preciosms: 0.059
        },
        {
          cantidad: "De 900,001 a 1’000,000 SMS", preciosms: 0.057
        },
      ]
    };
  },
  computed: {
    
  },
  methods: {
    calcularMultiplicacion() {
      try {
        let multiplicador;

        switch (true) {
          case (this.quantityMessage >= 5001 && this.quantityMessage <= 10000):
            multiplicador = 0.077;
            break;
          case (this.quantityMessage >= 10001 && this.quantityMessage <= 15000):
            multiplicador = 0.075;
            break;
          case (this.quantityMessage >= 15001 && this.quantityMessage <= 20000):
            multiplicador = 0.072;
            break;
          case (this.quantityMessage >= 20001 && this.quantityMessage <= 50000):
            multiplicador = 0.07;
            break;
          case (this.quantityMessage >= 50001 && this.quantityMessage <= 100000):
            multiplicador = 0.069;
            break;
          case (this.quantityMessage >= 100001 && this.quantityMessage <= 200000):
            multiplicador = 0.067;
            break;
          case (this.quantityMessage >= 200001 && this.quantityMessage <= 300000):
            multiplicador = 0.065;
            break;
          case (this.quantityMessage >= 300001 && this.quantityMessage <= 500000):
            multiplicador = 0.062;
            break;
          case (this.quantityMessage >= 500001 && this.quantityMessage <= 800000):
            multiplicador = 0.06;
            break;
          case (this.quantityMessage >= 800001 && this.quantityMessage <= 900000):
            multiplicador = 0.059;
            break;
          case (this.quantityMessage >= 900001 && this.quantityMessage <= 1000000):
            multiplicador = 0.057;
            break;
          default:
            multiplicador = 0;
        }

        const resultado = this.quantityMessage * multiplicador;
        console.log(resultado.toFixed(2));
        this.precioTotal = resultado.toFixed(2);
      }
      catch (e) {
        console.log("Error: " + e.message)
      }
    },
  },
};
</script>
<style>
@media (min-width: 768px) {
  .grid {
    display: grid;
    grid-template-columns: 50%;
    grid-template-rows: 100%;
  }
}

.item-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}

.item-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}
</style>
