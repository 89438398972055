
import { createRouter, createWebHashHistory } from 'vue-router'

//Componentes
import HomeComponent from "@/home/Home"
import PrecioComponent from "@/views/Precio"
import ConsultaComponentt from "@/views/Consulta"


//Definir objeto ruta de componentes

const routes = [
    { path: '/' , component: HomeComponent },
    { path: '/precio', component: PrecioComponent},
    { path: '/consulta', component: ConsultaComponentt},
    
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
