<template>
  <div id="NavBar" class="bar-fixed">
    <nav class="navbar navbar-expand-lg ">
      <div class="container">
        <a class="navbar-brand" href="#">
          <img src="@/assets/smsmasivos_logo_white.svg" style="width: 240px" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <router-link to="/" class="nav-link  text-color" aria-current="page"
                >Inicio</router-link
              >
            </li>
            <li class="nav-item">
              <a class="nav-link  text-color">Integraciones</a>
            </li>
            <li class="nav-item">
              <router-link to="/precio" class="nav-link  text-color">Precio</router-link>
            </li>
          </ul>
          <div class="d-flex">
            <button class="btn me-1  text-color">Iniciar Sesion</button>
            <button class="btn btn-primary ms-1">
              <router-link to="/consulta" class="nav-link text-start">Solicitar Demo</router-link>
            </button>
          </div>
        </div>
      </div>
    </nav>
  </div>
  <!--Responsive-->
  <div
    class="offcanvas offcanvas-start"
    tabindex="-1"
    id="offcanvasExample"
    aria-labelledby="offcanvasExampleLabel"
  >
    <div class="offcanvas-header">
      <button
        type="button"
        class="btn-close"
        data-bs-dismiss="offcanvas"
        aria-label="Close"
      ></button>
    </div>
    <div class="offcanvas-body">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <router-link
            to="/"
            class="nav-link active text-start"
            aria-current="page"
            >Inicio</router-link
          >
        </li>
        <li class="nav-item">
          <a class="nav-link text-start" href="#">Integraciones</a>
        </li>
        <li class="nav-item">
          <router-link to="/precio" class="nav-link text-start"
            >Precios</router-link
          >
        </li>
        <li class="nav-item">
          <a class="nav-link text-start text-color"  href="#">Iniciar Sesion</a>
        </li>
        <li class="nav-item">
          <router-link to="/consulta" class="nav-link text-start">Solicitar Demo</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "NavBarComponent",
};
</script>
<style scoped>
#id{
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9999;
  
}
.bar-fixed{
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  z-index: 999999;
  animation: stickySlideDown 0.65s cubic-bezier(0.23, 1, 0.32, 1) both;
  transition: all 0.65 ease-in-out;
  background-color: aliceblue;
}
li,button{
  font-weight: 600;
}
</style>
