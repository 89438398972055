<template>
  
    <!-- <img alt="Vue logo" src="./assets/logo.png">
  <HelloWorld msg="Welcome to Your Vue.js App"/> -->
  <navbar-component />
    <router-view></router-view>
  <footer-component />
</template>

<script>

export default {
    name: "App",
    components: { 
      'navbar-component': require("@/components/NavBar.vue").default,
      'footer-component': require("@/components/Footer.vue").default
     }
}
</script>

<style>
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
html, body {
    max-width: 100% !important;
    overflow-x: hidden !important;
}
body { min-height: 100vh; }
#app {
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  
}

</style>
