<template>
    <footer class="footer py-4">
        <div class="row">
            <div class="col"></div>
            <div class="col">
                <ul class="nav flex-column">
                    <li class="nav-item">
                        <a class="nav-link link-ref  active" aria-current="page" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <router-link to="/consulta" class="nav-link link-ref " aria-current="page">Solicitar Demo</router-link>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link link-ref " aria-current="page" href="#">Mas Servicios</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link link-ref " aria-current="page" href="#">Contacto</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link link-ref " aria-current="page" href="#">Acceso a Usuarios</a>
                    </li>
                </ul>
            </div>
            <div class="col"></div>
        </div>
        <hr>
        <div class="row">
            <div><p class="link-ref">©2023 · Todos los derechos reservados - Envio.pe es un desarrollo Privado</p></div>
        </div>
    </footer>
</template>
<script>
export default {
    name: "FooterComponent",
};
</script>
<style>
.footer {
    background: #2c234d;
}

.link-ref{
    color: #a39eb9;
}
</style>